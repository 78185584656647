import React from "react"
import Button from "../../../../fundamentals/button"
import PlusIcon from "../../../../fundamentals/icons/plus-icon"
import BreadCrumb from "../../../../molecules/breadcrumb"

const ShippingProfiles = () => {
  return (
    <div>
      <BreadCrumb
        previousRoute="/a/settings"
        previousBreadcrumb="Settings"
        currentPage="Shipping"
      />
      <div className="w-full flex">
        <div className="w-[35%] flex flex-col items-start">
          <b>Phí vận chuyển</b>
          <span>
            Thêm phí vận chuyển mới cho các khu vực vận chuyển khác nhau
          </span>
          <Button
            className="rounded-rounded mt-4 w-[320px] inter-base-regular"
            variant="primary"
            size="large"
          >
            <PlusIcon />
            Thêm khu vực vận chuyển
          </Button>
        </div>
        <div className="w-[65%] ">
          <div className="flex items-center justify-center flex-1 group bg-grey-0 rounded-rounded p-large border border-grey-20 h-full">
            <span>Không có khu vực vận chuyển nào được kích hoạt</span>
          </div>
        </div>
      </div>
      <div className="border-gray-300 w-full border-[0.5px] my-7"></div>
      <div className="w-full flex">
        <div className="w-[35%] flex flex-col items-start">
          <b>Nhà vận chuyển</b>
          <span>Kết nối với các nhà vận chuyển</span>
          <Button
            className="rounded-rounded mt-4 w-[320px] inter-base-regular"
            variant="primary"
            size="large"
          >
            <PlusIcon />
            Thêm nhà vận chuyển
          </Button>
        </div>
        <div className="w-[65%] ">
          <div className="flex items-center justify-center flex-1 group bg-grey-0 rounded-rounded p-large border border-grey-20 h-full">
            <span>Không có nhà vận chuyển nào được kích hoạt</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShippingProfiles
